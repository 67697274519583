import React, {
    Component
} from 'react';



import Layout from '../../components/layout'

class About extends Component{


    
    render() {
        return ( 
                <Layout>
                  <p>Blog Main</p>
                </Layout>
        )
    }
}

export default About